.App {
  text-align: center;
}

.app-logo {
  width: 32px;
  height: 26px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  background: linear-gradient(90deg, #672280 0%, #A626D3 100%);
  ;
  max-height: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
  font-family: karla;
  font-weight: 700;
  color: white;
}

.header-left {
  display: flex;
  align-items: center;
  justify-content: start;
}

.header-left h1 {
  font-size: 1.25rem;
  padding: 20px;
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: end;
}

.header-right h2 {
  font-size: .80rem;
  font-family: inter;
  font-weight: 700;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 37px;
}

.meme-form {
  margin-left: 36px;
  margin-right: 36px;
}

.meme-form .meme-form--inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.25rem;
  margin-bottom: 0.9375rem;
}

.meme-form--input {
  width: 45%;
  height: 2.1875rem;
  border: 1px solid #D5D4D8;
  border-radius: 5px;
  color: #0D0D0D;
  font-size: 12px;
  font-family: Karla;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-indent: 10px;
  margin: 0;
}

.meme-form .meme {
  position: relative;
}

.meme-form .meme-form--image {
  margin-top: 2rem;
  max-width: 100%;
  max-height: 20rem;
  align-self: center;
}

.meme-form .meme-form--button {
  font-size: 1rem;
  font-family: karla;
  font-weight: 700;
  color: white;
  width: 100%;
  height: 40px;
  background: linear-gradient(135deg, #711F8D 0%, #A818DA 100%);
  border: none;
  border-radius: 5px;
  align-self: center;
}

.meme--text {
  position: absolute;
  width: 80%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  margin: 15px 0;
  padding: 0 5px;
  font-family: impact, sans-serif;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: white;
  letter-spacing: 1px;
  text-shadow:
    2px 2px 0 #000,
    -2px -2px 0 #000,
    2px -2px 0 #000,
    -2px 2px 0 #000,
    0 2px 0 #000,
    2px 0 0 #000,
    0 -2px 0 #000,
    -2px 0 0 #000,
    2px 2px 5px #000;
}

.bottom {
  bottom: 0rem;
}

.top {
  top: 1.5rem;
}

.meme-form--button:hover {
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}